/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import 'bootstrap/dist/css/bootstrap-grid.min.css'

import React from 'react'

import 'react-image-lightbox/style.css'
import './static/bootstrap.css'

import { ThemeProvider } from './src/context/ThemeContext'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)

const insertJS = () => {
  // const addJS = (src) => {
  //   const s = document.createElement(`script`)
  //   s.type = `text/javascript`
  //   s.src = src
  //   document.getElementsByTagName(`head`)[0].appendChild(s)
  // }

  // const HSForms = '//js.hsforms.net/forms/embed/v2.js'
  // const HSForms = '//js.hsforms.net/forms/v2.js'
  // addJS(HSForms)

  // not needed anymore
  // const polifyll = 'https://polyfill.io/v3/polyfill.min.js'
  // addJS(polifyll)
}

export const onInitialClientRender = () => {
  insertJS()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.pathname.startsWith('/jobs')) {
    // reload the Jobs page - for first access (Gatsby internal routing) to init external scripts
    if (prevLocation && !prevLocation.pathname.startsWith('/jobs')) {
      window.location.reload()
    }
  }
}
